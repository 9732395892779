import {defineStore} from 'pinia'
import {getUserInfo} from '@/api/sysLogin'

export const userStore = defineStore('user', {
    state: () => ({
        id: "",
        nickName: "",
        roles: [],
        menuPermissions: []
    }),
    getters: {
        getTenantId() {
            return sessionStorage.getItem('tenantId') ? sessionStorage.getItem('tenantId') : 'demo'
        },
        getNickName(state) {
            return state.nickName
        },
        getUserId(state) {
            return state.id
        },
        getPermissions(state) {
            return state.menuPermissions
        },
        getRoles(state) {
            return state.menuPermissions
        }
    },
    actions: {
        setUserHandle(user: any) {
            this.id = user.id;
            this.nickName = user.nickName;
            this.roles = user.roles;
            this.menuPermissions = user.menuPermissions;
        }
    },
})
