import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { mainMenu } from '@/config/mainMenu'

const routes: Array<any> = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/login/index.vue'),
    },
    {
        path: '/find-pass',
        name: 'find_password',
        component: () => import('@/pages/login/find-password.vue'),
    },
    {
        path: '/404',
        name: 'not_found',
        component: () => import('@/pages/login/not_found.vue')
    },
    {
        path: '/no-result',
        name: 'no_result',
        component: () => import('@/pages/login/not_result.vue'),
    },
    {
        path: '/',
        component: () => import('@/skeleton/workspace/index.vue'),
        redirect: '/video-list',
        children: mainMenu
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
