import {get, post} from '@/axios/index'

export function sysLogin(loginBody: any) {
    return post(
        `login`, loginBody 
    );
}

export function getUserInfo() {
    return get(
        `userInfo`
    );
}