<template>
    <n-config-provider :theme="theme">
        <router-view/>
    </n-config-provider>
</template>

<script setup lang="ts">
import { onMounted, computed } from 'vue'
import pinia from '@/store/createPinia'
import { useStore } from '@/store/index'

const store = useStore(pinia);

onMounted(() => {
    handleInitThemeInfo()
})

const theme = computed(() => store.getSystemTheme)

function handleInitThemeInfo(){
    const _theme = sessionStorage.getItem('systemTheme')
    if(_theme == 'dark') {
        store.handleSetDark();
    } else {
        sessionStorage.setItem('systemTheme', 'light');
        store.handleSetLight();
    }
}
</script>

<style lang="less">
.page-container{
    padding: 20px;
    height: 100vh;
}
</style>
