import router from '@/router'
import {userStore} from '@/store/user'
import {getUserInfo} from '@/api/sysLogin'
import pinia from "@/store/createPinia";
import {message} from '@/utils/discreteApi'

const whiteList = ['login', 'not_found', 'find_password']

router.beforeEach((to: any, from: any, next: any) => {
    if (to.name == 'login' || to.name == 'find_password' || to.name == 'no_result') {
        next();
        return;
    }

    let token: string | null = sessionStorage.getItem("token");
    if (!token) {
        next(`/login`);
        return;
    }

    if (!userStore().getUserId) {
        const store = userStore(pinia)
        getUserInfo().then((res: any) => {
            store.setUserHandle(res.data)
            next();
        }).catch(err => {
            next({path: '/login'})
        })
    } else {
        next();
    }

})
