import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import pinia from '@/store/createPinia';
import './utils/permission'

// 引入全局样式
import "./assets/style/public.css"
// 引入框架
import Naive from 'naive-ui'


createApp(App).use(Naive).use(pinia).use(router).mount('#app')
