import { defineStore } from 'pinia'
import type { GlobalTheme } from 'naive-ui'
import { darkTheme } from 'naive-ui'

export const useStore = defineStore('main', {
    state: () => ({ 
        theme: <GlobalTheme | null>(null)
    }),
    getters: {
        getSystemTheme(state) {
            return state.theme;
        }
    },
    actions: {
        handleSetDark(){
            this.theme = darkTheme
            sessionStorage.setItem('systemTheme', 'dark')
        },
        handleSetLight(){
            this.theme = null
            sessionStorage.setItem('systemTheme', 'light')
        }
    }
})