import { h, Component } from 'vue'
import { NIcon } from 'naive-ui'
import { RouterLink } from 'vue-router'

// 渲染图标
export function renderIcon (icon: Component, color?: string) {
    let parameter: any = null
    if(color)
        parameter = { color: color }
    return () => h(NIcon, parameter, { default: () => h(icon) })
}

// 渲染路由
export function renderRouterLink (to: string, text: string, color?: string) {
    let parameter: any = null
    if(color)
        parameter = { color: color }
    return () => h(
        RouterLink,
        {
            to: to,
            style: parameter,
        },
        { default: () => text }
    )
}