import { ConfigProviderProps, 
        createDiscreteApi } from 'naive-ui'
import { computed } from 'vue'
import pinia from '@/store/createPinia'
import { useStore } from '@/store/index'

const store = useStore(pinia);

// 使用提示
const configProviderPropsRef = computed<ConfigProviderProps>(() => ({
    theme: store.getSystemTheme
}))
const { dialog, notification, message, loadingBar } = createDiscreteApi(
    ['dialog', 'notification', 'message', 'loadingBar'],
    {
        configProviderProps: configProviderPropsRef,
        notificationProviderProps: {
            placement: 'top-right',
            max: 3
        }
    }
)

export {
    dialog, 
    notification, 
    message,
    loadingBar
}