import { renderIcon, renderRouterLink } from "@/utils/render";
import { Video, ListDetails, AdjustmentsHorizontal, Users } from '@vicons/tabler';

export const mainMenu = [
    // {
    //     name: 'video',
    //     path: '/video',
    //     label: '视频管理',
    //     key: 'video',
    //     icon: renderIcon(Video),
    //     children: [
    //         {
    //             name: 'video-list',
    //             path: '/video-list',
    //             label: renderRouterLink('/video-list', '视频列表'),
    //             key: 'video-list',
    //             component: () => import('@/pages/video/list/index.vue')
    //         },
    //         {
    //             name: 'video-review',
    //             path: '/video-review',
    //             label: renderRouterLink('/video-review', '视频审核'),
    //             key: 'video-review',
    //             component: () => import('@/pages/video/review/index.vue')
    //         },
    //     ]
    // },
    // {
    //     name: 'category-manage',
    //     path: 'category-manage',
    //     label: '分类管理',
    //     icon: renderIcon(ListDetails),
    //     key: 'category-manage',
    //     children: [
    //         {
    //             name: 'channel-list',
    //             path: '/channel-list',
    //             label: renderRouterLink('/channel-list', '分区管理'),
    //             key: 'channel-list',
    //             component: () => import('@/pages/category-manage/channel/index.vue')
    //         },
    //         {
    //             name: 'activity-list',
    //             path: '/activity-list',
    //             label: renderRouterLink('/activity-list', '活动管理'),
    //             key: 'activity-list',
    //             component: () => import('@/pages/category-manage/activity/index.vue')
    //         },
    //     ]
    // },
    // { type: 'divider', path: '/' },
    {
        name: 'law-manage',
        path: 'law-manage',
        label: '法律管理',
        icon: renderIcon(ListDetails),
        key: 'law-manage',
        children: [
            {
                name: 'texttemplate-list',
                path: '/texttemplate-list',
                label: renderRouterLink('/texttemplate-list', '模板列表'),
                key: 'texttemplate-list',
                component: () => import('@/pages/law-manage/texttemplate/index.vue')
            },
            {
                name: 'legalcase-list',
                path: '/legalcase-list',
                label: renderRouterLink('/legalcase-list', '案由列表'),
                key: 'legalcase-list',
                component: () => import('@/pages/law-manage/legalcase/index.vue')
            },
        ]
    },
    { type: 'divider', path: '/' },
    {
        name: 'account',
        path: '/account',
        label: '账户管理',
        icon: renderIcon(Users),
        key: 'account',
        children: [
            {
                name: 'account-user',
                path: '/account-user',
                label: renderRouterLink('/account-user', '用户账户'),
                key: 'account-user',
                component: () => import('@/pages/account/user/index.vue')
            },
            {
                name: 'account-admin',
                path: '/account-admin',
                label: renderRouterLink('account-admin', '管理员账户'),
                key: 'account-admin',
                component: () => import('@/pages/account/admin/index.vue')
            },
        ]
    },
    {
        name: 'system-config',
        path: '/system-config',
        label: '系统配置',
        icon: renderIcon(AdjustmentsHorizontal),
        key: 'system-config',
        children: [
            // {
            //     name: 'advertisement',
            //     path: '/advertisement',
            //     label: renderRouterLink('/advertisement', '弹屏广告'),
            //     key: 'advertisement',
            //     component: () => import('@/pages/system-config/advertisement/index.vue')
            // },
            // {
            //     name: 'sensitive-word',
            //     path: '/sensitive-word',
            //     label: renderRouterLink('/sensitive-word', '敏感词设置'),
            //     key: 'sensitive-word',
            //     component: () => import('@/pages/system-config/sensitive-word/index.vue')
            // },
            {
                name: 'areatext',
                path: '/areatext',
                label: renderRouterLink('/areatext', '区域文案'),
                key: 'areatext',
                component: () => import('@/pages/system-config/areatext/index.vue')
            },
            {
                name: 'setting',
                path: '/setting',
                label: renderRouterLink('/setting', '全局设置'),
                key: 'setting',
                component: () => import('@/pages/system-config/setting/index.vue')
            },
        ]
    },
];